import React, { FC, useState, useRef, useEffect } from "react"
import { observer } from "mobx-react-lite"
import type {PropsWithChildren} from 'react';
import { ViewStyle, Dimensions, Animated, View, Easing, StyleSheet } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { AppStackScreenProps } from "app/navigators"
import { Screen, Text } from "app/components"
import { useStores } from "app/models"
import { 
  NativeBaseProvider, 
  Button,
  AspectRatio,
  Image,
  Stack,
  Heading,
  HStack,
  Center,
  TextArea,
  Box,
  Pressable,
  Spacer,
  Badge,
  Flex,
  Divider,
  ScrollView,
  Progress,
  VStack,
  Spinner,
  Container,
  Input,
  InputGroup,
  InputLeftAddon,
  HamburgerIcon,
  Popover,
  Icon,
  PresenceTransition,
  AlertDialog
 } from "native-base";
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { TypeAnimation } from 'react-type-animation';
import Ionicons from '@expo/vector-icons/Ionicons';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Fade } from "react-awesome-reveal";
import fetch from 'node-fetch';
import { Video, ResizeMode } from 'expo-av'
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
// import Animated, {
//   FadeIn,
//   FadeOut,
//   FadeInDown,
//   FadeOutDown,
//   useSharedValue,
//   useAnimatedStyle,
//   withTiming,
//   useDerivedValue,
//   interpolate,
//   withRepeat,
// } from "react-native-reanimated";
const { width: DEVICE_WIDTH, height: DEVICE_HEIGHT } = Dimensions.get("window");
// import fs from 'fs';

// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface EnvisionScreenProps extends NativeStackScreenProps<AppStackScreenProps<"Envision">> {}

type FadeInViewProps = PropsWithChildren<{style: ViewStyle}>;
type ImageFadeProps = PropsWithChildren<{}>;

const ImageFade: FC = () => {
  const fadeAnim = useRef(new Animated.Value(1)).current; // Initial value for opacity: 0
  const fadeAnimOut = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

  useEffect(() => {
    // Animated.loop(
    const fire = () => {
    Animated.parallel([
    Animated.timing(fadeAnim, {
      toValue: 0,
      easing: Easing.linear,
      duration: 2000,
      useNativeDriver: false,
    }),
    Animated.timing(fadeAnimOut, {
      toValue: 1,
      easing: Easing.linear,
      duration: 2000,
      useNativeDriver: false,
    })
  ])
  .start();
}
  fire()
  }, [fadeAnim]);

  return (
    <Box>
    <Animated.Image style={{ position: "absolute", height: 1000, width: 1000, opacity: fadeAnim }} source={{uri: require("../../assets/images/00001-4112389229.png")}}></Animated.Image>
    <Animated.Image style={{ position: "absolute", height: 1000, width: 1000, opacity: fadeAnimOut }} source={{uri: require("../../assets/images/00001-4112389228.png")}}></Animated.Image>
    </Box>
    // <Animated.Image // Special animatable View
    //   size="100%" resizeMode="cover" opacity="70%"
    //   source={{
    //     uri: require("../../assets/images/00060-101612930.png")
    //   }}
      
    //   // style={{
    //   //   ...props.style,
    //   //   opacity: fadeAnim, // Bind opacity to animated value
    //   // }}
    //   >
    //   </Animated.Image>
  );
};

const FadeInView: FC<FadeInViewProps> = props => {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

  useEffect(() => {
    // Animated.loop(
    const fire = () => {
    Animated.sequence([
    Animated.timing(fadeAnim, {
      toValue: 1,
      // easing: Easing.back(1),
      duration: 100,
      useNativeDriver: false,
    }),
    // Animated.timing(fadeAnim, {
    //   toValue: 0,
    //   // easing: Easing.back(1),
    //   duration: 2000,
    //   useNativeDriver: false,
    // })
  ])
  // )
  .start();
}
  fire()
  }, [fadeAnim]);

  return (
    <Animated.View // Special animatable View
      style={{
        ...props.style,
        opacity: fadeAnim, // Bind opacity to animated value
      }}>
      {props.children}
    </Animated.View>
  );
};
const words = ["one", "two", "three", "four"];

// const WordComponent: FC = () => {
//   const [index, setIndex] = useState(0)
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setIndex(index + 1)
//     }, 1000)
//   return () => {
//     // setIndex(0)
//     clearInterval(interval)
//   }
//   });
//   return (
//     <Text size="xl" style={{color: "white"}}>{words[index]}</Text>
//   )
// }
const FadeUpView: FC<FadeInViewProps> = props => {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const fadeAnimOut = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const [index, setIndex] = useState(-1)
  useEffect(() => {
    /* 
       We wrap this in a custom function instead of using 
       Animated.loop so that we can control index iteration
       from directly within the component
    */
    const loop = () => {
    Animated.sequence([
      Animated.parallel([
    Animated.timing(fadeAnimOut, {
      toValue: 100,
      // easing: Easing.back(1),
      duration: 500,
      useNativeDriver: false,
    }),
    Animated.timing(fadeAnim, {
      toValue: 1,
      // easing: Easing.back(1),
      duration: 500,
      useNativeDriver: false,
    }),
  ]),
  Animated.parallel([
    Animated.timing(fadeAnimOut, {
      toValue: 200,
      // easing: Easing.back(1),
      duration: 500,
      useNativeDriver: false,
    }),
    Animated.timing(fadeAnim, {
      toValue: 0,
      // easing: Easing.back(1),
      duration: 500,
      useNativeDriver: false,
    }),
  ]),
  ]).start(() => {
    fadeAnim.setValue(0)
    fadeAnimOut.setValue(0)
    loop()
  });
  setIndex(index => (index < words.length - 1) ? index + 1 : 0)
  // index <= words.length ? setIndex(index + 1) : setIndex(0)
}
  loop()
  }, [fadeAnim, fadeAnimOut]);

  return (
    <Animated.View // Special animatable View
      style={{
        // ...props.style,
        opacity: fadeAnim, // Bind opacity to animated value
        transform: [{translateY: fadeAnimOut}]
      }}>
      <Text size="xl" style={{color: "white"}}>{words[index]}</Text>
    </Animated.View>
  );
};


export const EnvisionScreen: FC<EnvisionScreenProps> = observer(function EnvisionScreen(this: any) {
  const [token, setToken] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  WebBrowser.maybeCompleteAuthSession();

  const [request, response, promptAsync] = Google.useAuthRequest({
    androidClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
    iosClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
    webClientId: '494683979521-9famk8tqivb0ckqv8hd0369jsdvca1pk.apps.googleusercontent.com'
  });
  useEffect(() => {
    if (response?.type === "success") {
      setToken(response.authentication.accessToken);
      getUserInfo();
    }
  }, [response, token]);

  const getUserInfo = async () => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/userinfo/v2/me",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const user = await response.json();
      setUserInfo(user);
    } catch (error) {
      // Add your own error handler here
    }
  };
  
  // icon state
  const [generating, setGenerating] = React.useState(false);
  // generate hover state
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [image, setImage] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [prompt, setTextAreaValue] = useState(null);
  const {
    authenticationStore: { isAuthenticated, authEmail, setAuthEmail, setAuthToken, validationError },
  } = useStores()
  
  // const getImage = async (inputImage: any, userPrompt: String) => {
  const getImage = async () => {
    const inputImage = "";
    const userPrompt = "";

    setAuthEmail("")
    setAuthToken("")

    useEffect(() => {
      return () => {
        setAuthEmail("")
        setAuthToken("")
      }
    }, [])
    return
    const url = "http://envision.castelln.com:7861/sdapi/v1/txt2img"

    // const userPrompt = "retail boutique selling high end soap, moisturizer, bath products, spa products and beauty products, inspired by Restoration Hardware, space is approximately 1000 sq ft."
    const prompt = `RAW photo, a photo of the interior of a ${userPrompt}, store shelves, display tables, retail space, dreamlike, (studio lighting, soft fill lighting, natural light, golden hour, sunset:1.2), bokeh, depth of field, surreal, (soft lighting), realistic shadows, high quality, film grain, 8k uhd, dslr, Fujifilm XT3`
    const negativePrompt = "(semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime:1.3), (overexposed), (oversaturated), (overhead lighting, bright overhead lights:1.2), close up, cropped, out of frame, worst quality, low quality, jpeg artifacts, ugly, duplicate, morbid, people, person, blurry, cluttered, messy"

    const payload = {
      "prompt": prompt,
      "seed": -1,
      "steps": 25,
      "cfg_scale": 9,
      "width": 1024,
      "height": 768,
      "restore_faces": false,
      "tiling": false,
      "negative_prompt": negativePrompt,
      "sampler_index": "DPM++ 2M Karras",
      "send_images": true,
      "alwayson_scripts": {
          "controlnet": {
              args: [
                  {
                      "input_image": inputImage,
                      "module" : "none",
                      "model": "controlnetPreTrained_depthV10 [400750f6]",
                      "control_mode": 1,
                      "guidance_start" : 0.25,
                      "guidance_end": 1
                  }
              ]
          }
      }
    }
    const response = await fetch(url, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: {'Content-Type': 'application/json'}
    });
    const data = await response.json();

    // console.log(data.images[0])
    setFinalImage(data.images[0]);
  }
  const pickImage = async () => {
  // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: true,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      setImage(result.assets[0]);
    }
  }

function beegTest() {
  if (generating) {
    // return (
    // <ImageFade/>
    // )
  } else {
    return (
      <Video
      source={{
        uri: require("../../assets/images/video.mp4")
      }}
      // useNativeControls
      resizeMode={ResizeMode.COVER}
      shouldPlay
      isMuted
      isLooping
      onReadyForDisplay={videoData => {
        videoData.srcElement.style.position = "initial"
        videoData.srcElement.style.height = "60em"
        videoData.srcElement.style.opacity = "70%"
      }}
    />
    )
  }
}
  
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  // Pull in navigation via hook
  // const navigation = useNavigation()
  return <Box rounded="lg" flex="1" marginTop="0" overflow="hidden" _web={{
  shadow: 2,
  borderWidth: 0
}}>
  <script src="https://accounts.google.com/gsi/client" async defer></script>
  {/* <ScrollView showsHorizontalScrollIndicator={false} contentContainerStyle={{ width: '100%' }}> */}
  <FadeInView style={{
    zIndex: 1}}>
    {/* <Fade direction="up" style={{zIndex: 1}}> */}
  <Box position="absolute" width="100%" height="10%" top="45" padding="0" left="0" alignItems="center">
    <HStack w="90%" alignItems="center" justifyContent="center">
    <HStack w="35%" alignItems="center" justifyContent="center">
      <HamburgerIcon size="6" color="white" />
      <Spacer />
      {/* <Fade direction="down"> */}
      <Text size="xs" style={{color: "white", fontWeight: "400", fontFamily: "Avenir Next"}}>TUTORIAL</Text>
      {/* </Fade> */}
      <Spacer />
      <Text size="xs" style={{color: "white", fontWeight: "400", fontFamily: "Avenir Next"}}>HOW IT WORKS</Text>
    </HStack>
  <Image resizeMode="contain" size="100%" flex="1" source={{
      uri: require("../../assets/images/logo-white.png")
    }} alt="Alternate Text" />
    
    <HStack w="35%" alignItems="center" justifyContent="center">
      <Text size="xs" style={{color: "white", fontWeight: "400", fontFamily: "Avenir Next"}}>ABOUT</Text>
      <Spacer />
      <Text size="xs" style={{color: "white", fontWeight: "400", fontFamily: "Avenir Next"}}>CONTACT US</Text>
      <Spacer />
      <Popover // @ts-ignore
  _backdrop={{bg: "black"}}
      placement={"left top"} trigger={triggerProps => {
        return <Button variant="unstyled" alignSelf="center" {...triggerProps} onPress={() => setIsOpen(true)}>
                <AntDesign name="user" size={24} color="white" />
              </Button>;
      }} isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
          <Popover.Content w="56">
          <Popover.Arrow />
          <Popover.CloseButton />
          <Popover.Header>Welcome!</Popover.Header>
          <Popover.Body>
            <Stack space={0} w="100%" alignItems="center">
            <Container style={styles.container}>
      {userInfo === null ? (
        <Button
          variant="unstyled"
          disabled={!request}
          onPress={() => {
            promptAsync();
          }}
        >
          <Image height="2em" resizeMode="cover" width="10em" source={{ uri: require("../../assets/images/btn_google_signin_light_normal_web.png")}} />
          </Button>
      ) : (
        <Text style={styles.text}>{userInfo.name}</Text>
      )}
    </Container>
            {/* <Image resizeMode="contain" size="100" width="100%" source={{
      uri: require("../../assets/images/login-google.png")
    }} alt="Alternate Text" /> */}
    <Spacer />
    <Image resizeMode="contain" size="100" width="100%"  source={{
      uri: require("../../assets/images/login-microsoft.png")
    }} alt="Alternate Text" />
    </Stack>
            </Popover.Body>
            <Popover.Footer justifyContent="center">
              <Button variant="ghost">Login</Button>
            </Popover.Footer>
          </Popover.Content>
        </Popover>
    </HStack>
    </HStack>
  </Box>
  </FadeInView>

  {/* <List>

  </List> */}
  {/* <Box zIndex={1} position="absolute" width="10%" height="10%" flex="1" top="0" padding="5" left="100"> */}
  {/* <HamburgerIcon size="6" color="white" /> */}
  {/* </Box> */}
  {/* <Box zIndex={1} position="absolute" width="10%" height="10%" flex="1" top="0" paddingTop="5" right="0">
  
  </Box> */}
  {/* <HStack space={2} flex={1} flexWrap="wrap" flexDirection="row" position="absolute" alignSelf="center" bottom="50%" justifyContent="center" alignItems="flex-end">
    <Text size="xl" style={{color: "white", alignItems: "flex-start"}}>Move</Text>
    <Spacer />
    <FadeUpView style={{top:0, left:0}} />
  </HStack> */}
    <Box height="60em">
    {beegTest()}
    {/* </Box> */}
    {/* <Image size="100%" resizeMode="cover" source={{
      // uri: image ? image.uri : 
      uri: require("../../assets/images/00060-101612930.png")
    }} alt="image" /> */}
    {/* <Center> */}
      <VStack alignItems="center" justifyContent="center">
      <Box position="absolute" bottom="100" padding="0" rounded="xl" shadow={8} justifyContent="center"
      // bg={{
      //   linearGradient: {
      //     colors: ['transparent', 'transparent'],
      //     start: [100, 0],
      //     end: [0, 100]
      //   }
      // }} 
      // backgroundColor="amber.400"
    mb="2em"
    h="3em"
    >
    <InputGroup 
    mt="2"
    ml="3"
    w={{
      base: "5%",
      md: "70em"
    }}
    flex="1"
    justifyContent={"flex-start"}
    >
      { !isAuthenticated ? <TypeAnimation
    sequence={[
      // Same String at the start will only be typed once, initially
      'a store selling candles, in modern style, natural light'.toUpperCase(),
      1000,
      'a store selling candles, in modern style, dim light'.toUpperCase(),
      1000,
      'a store selling kitchen appliances'.toUpperCase(),
      1000,
      'a retail boutique selling high end soap'.toUpperCase(),
      1000,
    ]}
    speed={50}
    style={{ color: "white", fontFamily: "Avenir Next", fontSize: '2em' }}
    repeat={Infinity}
  /> :
        <Input 
        variant="unstyled"
        // style={{opacity: 1}}
        // shadow={4} 
        color="white"
        fontSize="1em"
        // placeholderTextColor="white"
        // _light={{
        //   fontSize: "1xl",
        //   placeholderTextColor: "black",
        //   bg: "coolGray.100",
        //   _hover: {
        //     bg: "violet.500"
        //   },
        //   _focus: {
        //     bg: "violet.900:alpha.0"
        //   }
        // }} _dark={{
        //   bg: "coolGray.800",
        //   _hover: {
        //     bg: "coolGray.900"
        //   },
        //   _focus: {
        //     bg: "violet.900"
        //   }
        // }}
        focusOutlineColor={"white"}
        w={{
        // base: "70%",
        md: "100%"
      }}
      // placeholderTextColor={"black.100"}
      value={prompt} //onChange={e => setTextAreaValue(e.currentTarget.value)} // for web
      onChangeText={text => setTextAreaValue(text.toUpperCase())}
      onSubmitEditing={() => setGenerating(true)}
      />
    }
      </InputGroup>
      </Box>
      <Spacer/>
      <HStack space={100} position="absolute" bottom="50" paddingBottom="0" padding="0" rounded="xl" alignItems="center" justifyContent="center" mb="2em" h="3em">
      {/* <Button onPress={async () => {
      // pickImage()
      // console.log(textAreaValue)
      }
    } variant="ghost" maxW="96">
      <Text size="xs" style={{color: "white", opacity: "100%", fontWeight: "400", fontFamily: "Avenir Next"}}>
          PICK AN IMAGE
      </Text>
    </Button> */}
    {/* </Center> */}
    {/* <Spacer /> */}
    {/* <Center> */}
    <Button onPress={async () => {
      if (isAuthenticated) {
      setFinalImage(null)
      // await getImage(image.base64, prompt)
      await getImage()
    } else {
      setIsOpen(true)
    }
      // console.log(textAreaValue)
      }
    }
    onHoverIn={(() => {
      setIsHovered(true)
    })}
    onHoverOut={(() => {
      setIsHovered(false)
    })}
    variant="unstyled"
    maxW="96">
      <PresenceTransition visible={isHovered} initial={{
      opacity: 0.7,
      scale: 0.9
    }} animate={{
      opacity: 1,
      scale: 1,
      transition: {
        duration: 250
      }
    }}>
      <Fade direction="up">
      <Text size="sm" style={{color: "white", fontFamily: "Avenir Next"}}>
        GENERATE
      </Text>
      </Fade>
    </PresenceTransition>
    </Button>
    </HStack>
      </VStack>
    {/* </Center> */}
    </Box>
    {/* <Center> */}
    {/* <HStack space="1em" display="flex" alignItems="center" mt="3em" mb={6}> */}
    {/* <Image source={{
      uri: finalImage ? `data:image/png;base64,${finalImage}` : require("../../assets/images/placeholder-image.png")
    }} alt="image" display="flex" borderRadius={15} /> */}
    {/* </AspectRatio> */}
    {/* </Center> */}
    {/* </HStack> */}
    {/* </Center> */}
  {/* </ScrollView> */}
  {/* </Center> */}
  {/* </ScrollView> */}
  {/* </Box> */}
  {/* </VStack> */}
  {/* </ScrollView> */}
  </Box>
})

const $root: ViewStyle = {
  flex: 1,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 20,
    fontWeight: "bold",
  },
});